import React, { useRef } from 'react'
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"


//Import Components
import Header from "../components/header"
import Footer from "../components/footer"
import Arrow from '../images/forward-arrow-medium-dark-gray.svg'


const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); 

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const subheader = post.frontmatter.subheader
  const title = post.frontmatter.title
  const description = post.frontmatter.description
  const backgroundColor = post.frontmatter.backgroundcolor;
  const textColor = post.frontmatter.textcolor;
  const scrollContainer = post.frontmatter.scrollcontainer;
  const scrollCircle = post.frontmatter.scrollcircle;
  const scrollArrow = post.frontmatter.scrollarrow;
  const scrollArrow2 = post.frontmatter.scrollarrow2;
  const scrollText = post.frontmatter.scrolltext;
  const content = post.frontmatter.content;
  const heroImage = post.frontmatter.heroimage;
  const heroImageAlt = post.frontmatter.heroimagealt; 
  var i = 1;

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header/>
      <div className = "navbar-spacing"></div>
      <div className = {backgroundColor}>
        <div className = "project-container-title">
            <div className = {textColor}>
              <div className = "summary-subheader">{subheader}</div>
              <div className = "summary-header">{title}</div>
              <div className = "summary-text">{description}</div>
            </div>
            <div className = "home-right hero-right">
              <Img className = "hero-image" fluid={heroImage.childImageSharp.fluid} alt={heroImageAlt} />
            </div>
        </div>
        <div className = "scroll-section">
          <div className = {scrollContainer} role="button" tabIndex={0} onClick={executeScroll} onKeyDown={executeScroll}>
            <div className= {scrollCircle} id="scroll-circle"> 
                <div className= {scrollArrow} id="arrow1">
                </div>
                <div className= {scrollArrow2} id="arrow2">
                </div>
            </div>
            <div className = {scrollText} id="scroll-text" ref={myRef}> 
                View Work
            </div>
          </div>
        </div>
      </div>
      <div className = "project-container-grid">
        {content.map(({ type, html, image, section, imagealt }) => {
            if ({type}.type === "project-content") {
              return (
                <div className = {type} key = {i++} dangerouslySetInnerHTML={{
                  __html: {html}.html
                }} />
              )
            }
            else if ({type}.type === "project-content-grid") {
              return (
                <div className = "project-2-grid-container" key = {i++}>
                  <div className = "project-2-grid">
                    {section.map(({ html , image, imagealt })   => {
                      return (
                        <div key = {i++}>
                          <div className = "project-2-grid-section">
                            <Img className = "project-2-grid-image" fluid={image.childImageSharp.fluid} alt={imagealt} />
                            <div dangerouslySetInnerHTML={{
                              __html: {html}.html
                            }} />
                          </div>
                        </div>
                      )}
                    )}
                  </div>
                </div>
              )
            }
            else if ({type}.type === "project-content-grid-no-image") {
              return (
                <div className = "project-2-grid-container" key = {i++}>
                  <div className = "project-2-grid">
                    {section.map(({ html })   => {
                      return (
                        <div key = {i++}>
                          <div className = "project-2-grid-section">
                            <div dangerouslySetInnerHTML={{
                              __html: {html}.html
                            }} />
                          </div>
                        </div>
                      )}
                    )}
                  </div>
                </div>
              )
            }
            else if ({type}.type === "project-content-grid-3") {
              return (
                <div className = "project-2-grid-container" key = {i++}>
                  <div className = "project-3-grid">
                    {section.map(({ html , image, imagealt })   => {
                      return (
                        <div key = {i++}>
                          <div className = "project-3-grid-section">
                            <div>
                              <Img className = "persona-image" fluid={image.childImageSharp.fluid} alt={imagealt} />
                            </div>
                            <div dangerouslySetInnerHTML={{
                              __html: {html}.html
                            }} />
                          </div>
                        </div>
                      )}
                    )}
                  </div>
                </div>
              )
            }
            else if ({type}.type === "project-button") {
              return (
                <div className = 'full-project-container'>
                    <a href= {html} className='button-project full-project'>
                      View Full Project
                      <img className = "button-arrow" src={Arrow} alt="Forward Arrow"/>
                    </a>
                </div>
              )
            }
            else {
              return (
                <div key = {i++}>
                  <Img className = "project-image" fluid={image.childImageSharp.fluid} alt={imagealt} />
                </div>
              )
            }
          })
        }
      </div>
      <Footer/>
    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        backgroundcolor
        textcolor
        subheader
        scrollcontainer
        scrollcircle
        scrollarrow
        scrollarrow2
        scrolltext
        image {
          childImageSharp {
            fluid(maxWidth: 2240, quality: 100) {
                ...GatsbyImageSharpFluid
            }
          }
        }
        heroimage {
          childImageSharp {
            fluid(maxWidth: 2240, quality: 100) {
                ...GatsbyImageSharpFluid
            }
          }
        }
        heroimagealt
        imagealt
        content {
          type
          html
          image {
            childImageSharp {
              fluid(maxWidth: 2240, quality: 100) {
                  ...GatsbyImageSharpFluid
              }
            }
          }
          imagealt
          section {
            html
            image {
              childImageSharp {
                fluid(maxWidth: 2240, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
              }            
            }
            imagealt
          }
        }
      }
    }
  }
`
